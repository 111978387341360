import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Moment from 'react-moment';
import Layout from '../../components/layout';
import Markdown from 'react-markdown';
import { Cursor } from '../../components/Cursor';
import styled from 'styled-components';
import { Menu } from '../../components/Menu';
import { PageHeader } from '..';
import Card from '../../components/card';
import { API_URL } from '../../helpers/Constants';
import { Logo } from '../../components/Logo';

export const query = graphql`
  query ArticleQuery($slug: String!) {
    strapiGlobal {
      logo {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 500, quality: 100, placeholder: NONE)
          }
        }
      }
    }
    strapiArticle(slug: { eq: $slug }) {
      strapiId
      title
      description
      content
      published_at
      backgroundColor
      image {
        url
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 100, placeholder: NONE)
          }
        }
      }
      grid {
        ... on StrapiArticleGrid {
          image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: NONE
                  quality: 100
                )
              }
            }
          }
          Left {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  quality: 100
                  placeholder: NONE
                )
              }
            }
          }
          Right {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  quality: 100
                  placeholder: NONE
                )
              }
            }
          }
        }
      }
    }
  }
`;

const Article = ({ data }) => {
  const article = data.strapiArticle;
  console.log({ article });
  const MAX_CHAR_COUNT = 240;

  const [isExpanded, setIsExpanded] = React.useState(
    article.content.length < MAX_CHAR_COUNT
  );
  const [isHoveringMenu, setIsHoveringMenu] = React.useState(false);
  const seo = {
    metaTitle: article.title,
    metaDescription: article.description,
    shareImage: article.image,
    article: true,
  };

  const imageType2 =
    !article.image.localFile.childImageSharp?.gatsbyImageData &&
    article.image.url
      ? 'video'
      : 'data';

  const imageData =
    imageType2 === 'data'
      ? article.image.localFile.childImageSharp?.gatsbyImageData
      : `${/*process.env.NODE_ENV !== 'production' ? API_URL : ''*/ ''}${
          article.image.url
        }`;

  const videoRef = React.useRef(null);
  React.useEffect(() => {
    if (!videoRef.current) return;
    videoRef.current.muted = true;
    videoRef.current.defaultMuted = true;
  }, [imageData]);

  return (
    <Layout seo={seo}>
      <Cursor active={isHoveringMenu} />
      <Logo
        imageData={data.strapiGlobal.logo}
        isHovering={(hovering) => setIsHoveringMenu(hovering)}
      />
      <Menu isHovering={(hovering) => setIsHoveringMenu(hovering)} />
      <Container>
        <div
          style={{
            backgroundColor:
              article.backgroundColor && article.backgroundColor !== 'normal'
                ? article.backgroundColor
                : '#FADFD8',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            position: 'fixed',
            zIndex: -1,
          }}
        ></div>
        <div style={{ display: 'grid' }}>
          {imageType2 === 'video' ? (
            <video
              ref={videoRef}
              width={'100%'}
              src={imageData}
              alt={``}
              autoPlay
              muted
              loop
            />
          ) : (
            <GatsbyImage
              image={imageData}
              width={'100%'}
              layout='fullWidth'
              alt={data.strapiGlobal.siteName}
            />
          )}
        </div>
        <MarkdownContainer
          className='uk-section'
          style={{
            fontSize: 20,
            color: 'black',
            lineHeight: 1.5,
          }}
        >
          <div>
            <StyledMarkdown
              style={{ whiteSpace: isExpanded ? 'normal' : 'nowrap' }}
              source={
                isExpanded
                  ? article.content
                  : `${article.content.substring(0, MAX_CHAR_COUNT)}...`
              }
              escapeHtml={false}
            />

            {article.content.length >= MAX_CHAR_COUNT && (
              <a
                href='#'
                style={{ fontSize: isExpanded ? 40 : 30 }}
                onClick={(e) => {
                  e.preventDefault();
                  setIsExpanded((v) => !v);
                }}
                onMouseEnter={() => setIsHoveringMenu(true)}
                onMouseLeave={() => setIsHoveringMenu(false)}
              >
                {isExpanded ? `-` : `+`}
              </a>
            )}
          </div>
        </MarkdownContainer>
        {article.grid.map((g) => {
          return <GridRow data={data} g={g} />;
        })}
      </Container>
    </Layout>
  );
};

const GridRow = ({ data, g }) => {
  const videoRefA = React.useRef(null);
  const videoRefB = React.useRef(null);
  console.log({ g });

  React.useEffect(() => {
    if (!videoRefA.current) return;
    videoRefA.current.muted = true;
    videoRefA.current.defaultMuted = true;

    if (!videoRefB.current) return;
    videoRefB.current.muted = true;
    videoRefB.current.defaultMuted = true;
  }, [g.Right?.url, g.Left?.url, g.image?.url]);

  return g.Left && g.Right ? (
    <SideBySide>
      {g.Left.localFile?.childImageSharp?.gatsbyImageData ? (
        <GatsbyImage
          className='left'
          image={g.Left.localFile.childImageSharp.gatsbyImageData}
          key={`article__LeftImage__${g.id}`}
          width={'100%'}
          layout='fullWidth'
          alt={data.strapiGlobal.siteName}
          style={{ flex: 1 }}
        />
      ) : (
        <video
          className='left'
          ref={videoRefA}
          width={'100%'}
          src={g.Left.url}
          alt={``}
          autoPlay
          muted
          loop
          style={{ flex: 1 }}
        />
      )}
      {g.Left.localFile?.childImageSharp?.gatsbyImageData ? (
        <GatsbyImage
          className='right'
          image={g.Right.localFile.childImageSharp.gatsbyImageData}
          key={`article__Right__Image${g.id}`}
          width={'100%'}
          layout='fullWidth'
          alt={data.strapiGlobal.siteName}
          style={{ flex: 1 }}
        />
      ) : (
        <video
          className='right'
          ref={videoRefB}
          width={'100%'}
          src={g.Right.url}
          alt={``}
          autoPlay
          muted
          loop
          style={{ flex: 1 }}
        />
      )}
    </SideBySide>
  ) : g.image?.localFile?.childImageSharp?.gatsbyImageData ? (
    <GatsbyImage
      image={g.image.localFile.childImageSharp.gatsbyImageData}
      key={`article__Full__${g.id}`}
      width={'100%'}
      style={{
        marginTop: 15,
        marginBottom: 15,
      }}
      layout='fullWidth'
      objectFit='cover'
      alt={``}
    />
  ) : g.image?.url ? (
    <video
      ref={videoRefA}
      width={'100%'}
      src={g.image.url}
      alt={``}
      autoPlay
      muted
      loop
      style={{
        marginTop: 15,
        marginBottom: 15,
      }}
    />
  ) : (
    <span />
  );
};

const StyledMarkdown = styled(Markdown)`
  overflow: hidden;
  text-overflow: ellipsis;
  & > * {
    font-weight: 400;
  }
`;

const SideBySide = styled.div`
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;

  & .left {
    margin-right: 7px;
  }
  & .right {
    margin-left: 7px;
  }
  & .gatsby-image-wrapper {
    // max-height: 720px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    margin-top: 0px;
    margin-bottom: 0px;
    & .left,
    .right {
      margin-right: 0px;
      margin-left: 0px;
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }
`;

const Parallax = styled.div`
   {
    /* The image used */

    /* Set a specific height */
    // padding-bottom: 70%

    /* Create the parallax scrolling effect */
    // background-attachment: fixed;
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;

    @media (max-width: 1200px) {
      // padding-bottom: 90%;
    }

    @media (max-width: 600px) {
      // padding-bottom: 160%;
    }
  }
`;

const MarkdownContainer = styled.div`
  max-width: 60%;
  & p a {
    color: #797979 !important;
  }
  @media (max-width: 1200px) {
    max-width: 100%;
  }
`;

const Container = styled.div`
  padding: 100px;
  padding-top: 0;
  padding-bottom: 200px;

  @media (max-width: 1200px) {
    padding-right: 55px;
    padding-left: 55px;
  }

  @media (max-width: 600px) {
    padding-right: 15px;
    padding-left: 15px;
  }
`;

export default Article;
